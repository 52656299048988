import React, { useEffect, useState } from 'react';
import { useMediaQuery, useBreakpoint } from 'react-responsive';
import './SocialMediaApprovalComponent.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SocialMediaApprovalComponent = () => {
  const searchParams = new URLSearchParams(document.location.search);
  const refId = searchParams.get('refId');

  useEffect(() => {
    const apiUrl = 'https:///api.bombastic.ps/api/get_posts_data/' + refId;

    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((jsonData) => {
        console.log(jsonData.data.items_page_by_column_values);
        setImageData(jsonData.data.items_page_by_column_values.items);
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }, []);

  const [imageData, setImageData] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const handleNoteChange = (event, itemid) => {
    const updatedImageData = imageData.map((item) => {
      if (item.id === itemid) {
        item.column_values.find((col) => col.id === 'text').text =
          event.target.value;
      }
      return item;
    });
    setImageData(updatedImageData);
  };

  const handleApprove = (itemid) => {
    const updatedImageData = imageData.map((item) => {
      if (item.id === itemid) {
        item.column_values.find((col) => col.id === 'status3').text = 'Approved';
      }
      return item;
    });
    setImageData(updatedImageData);
    handleSave(itemid)
  };

  const handleDecline = (itemid) => {
    const updatedImageData = imageData.map((item) => {
      if (item.id === itemid) {
        item.column_values.find((col) => col.id === 'status3').text = 'Declined';
      }
      return item;
    });
    setImageData(updatedImageData);
    handleSave(itemid)
  };

  const handleSave = (itemid) => {
    setIsSaving(true);

    const itemToSave = imageData.find((item) => item.id === itemid);

    const { id } = itemToSave;
    const note = itemToSave.column_values.find((col) => col.id === 'text').text;
    const status = itemToSave.column_values.find((col) => col.id === 'status3')
      .text;

    const apiUrlNote = `https://api.bombastic.ps/api/update_posts_client?clientid=${refId}&itemid=${id}&value=${
      note ? note : ''
    }&columnid=text`;
    const apiUrlStatus = `https://api.bombastic.ps/api/update_posts_client?clientid=${refId}&itemid=${id}&value=${status}&columnid=status3`;

    Promise.all([
      fetch(apiUrlNote, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      fetch(apiUrlStatus, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ])
      .then((responses) => {
        console.log('API responses:', responses);
        toast.success('تم الحفظ', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
        });
        setIsSaving(false);
      })
      .catch((error) => {
        console.error('Error with API requests:', error);
        setIsSaving(false);
      });
  };

  const handleApproveAll = () => {
    setIsSaving(true); // Disable buttons during the approval process

    const approveAllRequests = imageData.map((item) => {
      const { id } = item;
      const apiUrlStatus = `https://api.bombastic.ps/api/update_posts_client?clientid=${refId}&itemid=${id}&value=Approved&columnid=status3`;
      item.column_values.find((col) => col.id === 'status3').text = 'Approved';

      return fetch(apiUrlStatus, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
    });
    const updateditems = imageData.map((item) => {
      item.column_values.find((col) => col.id === 'status3').text = 'Approved';

      return item;
    });
    setImageData(updateditems);

    Promise.all(approveAllRequests)
      .then((responses) => {
        console.log('All items approved:', responses);
        toast.success('تم الموافقة على جميع المنشورات', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
        });
        setIsSaving(false);
      })
      .catch((error) => {
        console.error('Error with API requests:', error);
        setIsSaving(false);
      });
  };

  // Define the media queries and breakpoints
  const isMobileDevice = useMediaQuery({ query: '(max-width: 480px)' });
  const isTabletDevice = useMediaQuery({ query: '(max-width: 768px)' });
  const isLaptop = useMediaQuery({ query: '(max-width: 1024px)' });
  const isDesktop = useMediaQuery({ query: '(max-width: 1200px)' });

  // Define the styles for different screen sizes
  const containerStyle = {
    width: '100%',
    margin: '0 auto',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const headerStyle = {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: '#333',
    textAlign: 'center',
    marginBottom:"20px"
  };

  const gridStyle = {
    width: '80%',
    display: 'grid',
    gridTemplateColumns: isMobileDevice
      ? '1fr'
      : isTabletDevice
      ? '1fr 1fr'
      : isLaptop
      ? '1fr 1fr 1fr'
      : isDesktop
      ? '1fr 1fr 1fr'
      : '1fr 1fr 1fr 1fr',
    gridGap: '1rem',
    direction:"rtl"
  };

  const cardStyle = {
    width: '100%',
    height: 'auto',
    border: '1px solid #ccc',
    borderRadius: '0.5rem',
    overflow: 'hidden',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  };

  const imageStyle = {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
  };

  const contentStyle = {
    padding: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };

  const noteStyle = {
    width: '100%',
    height: '4rem',
    resize: 'none',
    outline: 'none',
    borderColor:'#00000033',
    borderRadius:'10px',
    padding: '0.5rem',
    fontSize: '1rem',
    fontFamily: 'inherit',
    color: '#333',
    textAlign:'right'
  };

  const buttonGroupStyle = {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0.5rem 0',
  };

  const buttonStyle = {
    width: '40%',
    height: '2rem',
    border: 'none',
    borderRadius: '0.25rem',
    fontSize: '1rem',
    fontWeight: 'bold',
    color: '#fff',
    cursor: 'pointer',
  };

  const approveButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#4caf50',
  };

  const declineButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#f44336',
  };

  const saveButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#2196f3',
  };

  const approveAllButtonStyle = {
    width: '80%',
    height: '3rem',
    border: 'none',
    borderRadius: '0.5rem',
    fontSize: '1rem',
    fontWeight: 'bold',
    color: '#fff',
    backgroundColor: '#4caf50',
    cursor: 'pointer',
    margin: '1rem 0',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headerStyle}>مراجعة أعمال</h1>
    
      <div style={gridStyle}>
        {imageData.map((item) => {
          console.log(item)
          const { id } = item;
          const note = item.column_values.find((col) => col.id === 'text').text;
          const title = item.column_values.find((col) => col.id === 'text9').text;
          const status = item.column_values.find((col) => col.id === 'status3')
            .text;

          return (
            <div key={id} style={cardStyle}>
            {getItemContent(item)}
              <p
              dangerouslySetInnerHTML={{__html: title}}
              ></p>
              <div style={contentStyle}>
                <p style={{
                  textAlign:"right",
                  color:status=="Approved"?"green":"red"
                }}>{status=="Approved"?"موافق عليه":status=="Declined"?"مرفوض":""}</p>
                <textarea
                  style={noteStyle}
                  value={note}
                  onChange={(event) => handleNoteChange(event, id)}
                  placeholder="إضافة ملاحظة"
                />
                <div style={buttonGroupStyle}>
                <button
                    style={approveButtonStyle}
                    onClick={() => handleApprove(id)}
                  >
                    موافقة
                  </button>
                <button
                    style={declineButtonStyle}
                    onClick={() => handleDecline(id)}
                  >
                    رفض
                  </button>
               
                
                  
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <button
        style={approveAllButtonStyle}
        onClick={handleApproveAll}
        disabled={isSaving}
      >
        الموافقة على جميع الاعمال
      </button>
      <ToastContainer />
    </div>
  );
};

export default SocialMediaApprovalComponent;
function getItemContent(item) {
  const publicUrl = item.assets[0].public_url;

  // Extract the file extension from the URL
  const fileExtension = publicUrl.split('.').pop().toLowerCase().split('?')[0];

  
  if (fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png') {
    // Render an image if the file extension is for an image
    return (
      <a
        href={publicUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={publicUrl}
          alt={`Image ${item.id}`}
          className="img-fluid"
          style={{
            aspectRatio:16/9,
            maxHeight:"300px",
            objectFit:'contain',
          }}
        />
      </a>
    );
  } else if (fileExtension === 'mp4' || fileExtension === 'avi' || fileExtension === 'mov' || fileExtension === 'mkv') {
    // Render a video if the file extension is for a video
    return (
      <video controls width="100%" style={{
        aspectRatio:16/9,
        maxHeight:"300px"
      }}>
        <source src={publicUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    );
  } else {
    // Render a download link for other file types
    return (
      <a
      href={publicUrl}
      target="_blank"
      rel="noopener noreferrer"
    >
      <p>إضغط هنا لتنزيل الملف</p>
    </a>
    );
  }
}