import logo from './logo.svg';
import './App.css';
import SocialMediaApprovalComponent from './ListImages';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <>
     <div className="App">
      
     <SocialMediaApprovalComponent/>

    </div>
    </>
  );
}

export default App;
